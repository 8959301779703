@charset "utf-8";

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizespeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    display: block;
    max-width: 100%;
}

img {
    height: auto;
    vertical-align: bottom;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        transition-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
    }
}

@charset "utf-8";

*,
*::before,
*::after {
    box-sizing: border-box;
    user-select: none;
}

html {
    font-size: 62.5%;
}

body {
    font-family: Courgette, cursive, 'Zen Kurenaido', sans-serif;
    font-weight: 400;
    line-height: 1;
    color: #333;
    letter-spacing: 0;
    background-color: #333;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    display: block;
    width: 100%;
    height: 100%;
    color: #333;
    text-decoration: none;
}

button {
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.container {
    position: relative;
    max-width: 1920px;
    height: auto;
    padding: 0 15px;
    margin: 0 auto;
}
@media (min-width: 960px) {
    .container {
        padding: 0 60px;
    }
}


